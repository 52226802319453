<template>
  <div >
    <div class="main">
      <div name="Car View" v-if="!loading && ! addServices">
        <breadcrumb name="Car Models" title="All Car Models" subtitle="View"
                    @onTitleClicked="$router.push('/models')"/>
        <div>
          <vx-card title="Model Info." class="mb-base">
            <vs-row vs-type="flex">
              <vs-col
                vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12">
                <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                  <table class="sm-table">
                    <tr>
                      <td class="font-semibold p-4">Brand</td>
                      <td class="p-4">{{item.brand.name_en}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold p-4">English Name</td>
                      <td class="p-4">{{item.name_en}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold p-4">Year</td>
                      <td class="p-4">{{item.year}}</td>
                    </tr>
                  </table>
                </div>
              </vs-col>
              <vs-col
                vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12">
                <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
                  <table class="sm-table">
                    <tr>
                      <td class="font-semibold p-4">Type</td>
                      <td class="p-4">{{ item.car_type == '0'? 'Passenger' : 'Truck'}}</td>
                    </tr>
                    <tr>
                      <td class="font-semibold p-4">Arabic Name</td>
                      <td class="p-4">{{ item.name_ar}}</td>
                    </tr>
                  </table>
                </div>
              </vs-col>
            </vs-row>


          </vx-card>
          <vx-card title="Add Ons" class="mb-base"  style="position: relative">

            <div style="width: 100%; margin-top: -40px" align="right">
              <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addAddOnItem" >
                Add New
              </vs-button>
            </div>

            <vs-table
              ref="table"
              :sst="true"
              style="margin-top: 20px"
              :data="itemAddOns">

              <template slot="thead">
                <vs-th>Name</vs-th>
                <vs-th>Price</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <string-row :data="tr.adon.name_en"/>
                    <string-row :data="tr.price"/>
                    <actions-row
                      :editable = 'false'
                      @onDeleteClicked="deleteAddOn(tr.id)"/>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>

          <vx-card title="Services" class="mb-base" >

            <div style="width: 100%; margin-top: -40px" align="right">
              <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addServiceItem" >
                Add New
              </vs-button>
            </div>

            <vs-table
              ref="table"
              :sst="true"
              style="margin-top: 20px"
              :data="itemServices">

              <template slot="thead">
                <vs-th>Name in KMs</vs-th>
                <vs-th>Price</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <string-row :data="tr.km"/>
                  <string-row :data="tr.price"/>

                  <actions-row
                    @onDeleteClicked="deleteService(tr.id)"
                    @onEditClicked = "editService(tr)"
                    :copy = "true"
                    @onCopyClicked ="copyService(tr)"/>

                </vs-tr>
                </tbody>
              </template>
            </vs-table>

          </vx-card>

          <vx-card v-if="showWarranty" title="Warranty Details" class="mb-base">
            <div>
              <div class="english">
                <span class="font-semibold p-4">Warranty Arabic Title</span>
                <span >{{itemWarranty.ar.title}}</span>
                <br>
                <span class="font-semibold p-4">Warranty Arabic Description</span>
                <span>{{itemWarranty.ar.text}}</span>

              </div>
              <br>
              <div class="arabic">
                <span class="font-semibold p-4">Warranty English Title</span>
                <span >{{itemWarranty.en.title}}</span>
                <br>
                <span class="font-semibold p-4">Warranty English Description</span>
                <span>{{itemWarranty.en.text}}</span>
              </div>
            </div>

          </vx-card>

        </div>
      </div>

      <div  v-if="addServices" >
        <breadcrumb name="Car Models" title="All Car Models" subtitle="Add Service" @onTitleClicked="$emit('cancel')"/>
        <vx-card class="mb-base">
          <vs-row>
            <vs-col>
              <add-service :item="item" @cancel="addServices = false" @update="updateServices"/>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
    </div>

</template>

<script>
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import AddWarranties from "./add-warranties";
  import Services from "../../../../components/form/model-services/services";
  import Router from 'vue-router'
  import AddNewButton from "../../../../components/form/addnew-button";
  import StringRow from "../../../../components/table/rows/string-row";
  import ActionsRow from "../../../../components/table/rows/actions-row";
  import AddService from "./add-service";

  export default {
    name: 'view-item',
    components: {
      AddService,
      ActionsRow,
      StringRow,
      AddNewButton,
      Services,
      AddWarranties,
      Breadcrumb,
      Router
    },
    props: {

    },
    data() {
      return {
        servicesCounter: 0,
        warrantiesCounter: 0,
        modelID: 0,
        addAddOns: false,
        addServices: false,
        addWarranties: false,
        showWarranty: false,
        loading: true,
        sidebarData: {},
        model: {},
        item: {
          brand: {
            name_en: ""
          },
          name_en: "",
          year: "",
          services: [],
          car_type: '0',
        },
        itemAddOns: [],
        itemServices: [],
        itemWarranty: {
          ar: {
            text: '',
            title: ''
          },
          en: {
            text: '',
            title: ''
          }
        },
        form: {
          services: [{
              km: 0,
              price: 0,
              details: [{
                en: "",
                ar: "",
              }]
            }],
        }
      }
    },
    methods: {
      updateWarranties(data) {
        this.item.warranties = data;
        this.addWarranties = false;
        this.warrantiesCounter++;
      },
      updateServices(data) {
        this.itemServices = data.services;

        //TODO:: refactor itemServices to match data structure

        this.addServices = false;
        this.servicesCounter++;
      },
      deleteAddOn(id)
      {
        this.$vs.loading();
        this.$httpAdmin
          .get(`/adons/delete-model-adon/${id}`)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Successful operation",
              text: "Add On has been deleted successfully",
              color: 'success'
            });
            this.getModel();
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error 🙁",
              text: "Couldn't delete data on the server",
              color: 'danger'
            });
          })
      },
      deleteService(id)
      {
        this.$vs.loading();
        this.$httpCars
          .get(`models/deleteservice/${id}`)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Successful operation",
              text: "Add On has been deleted successfully",
              color: 'success'
            });
            this.getModel();
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error 🙁",
              text: "Couldn't delete data on the server",
              color: 'danger'
            });
          })
      },
      editService(item)
      {
        this.$router.push('/models/edit-service/' +this.modelID + "/" + item.id);

      },
      copyService(item)
      {
        this.$router.push('/models/copy-service/' +this.modelID + "/" + item.id);

      },
      getModel()
      {
        //console.log(payload);
        this.modelID = this.$route.params.id;
        this.$httpCars.get('/models/' + this.modelID)
          .then(r => {
            this.$vs.loading.close();
            console.log({item: r.data.data});
            this.item = r.data.data;
            this.itemServices = this.item.services;
            if(typeof this.item.warranties.en !== 'undefined' )
            {
              this.itemWarranty = this.item.warranties;
              this.showWarranty = true;
            }
            this.getAddOnsOfModel();

          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: 'Error is occurred!\nCouldn\'t Save Data!',
              color: 'danger'
            });
          });

      },
      addService(service) {
        console.log({service: service});
        this.itemServices.push(service)
      },
      getAddOnsOfModel() {
        this.$httpAdmin.get(`/adons/find-model-adons/${this.item.id}`)
          .then(res => {
            this.itemAddOns = res.data.data;
            //console.log({addOns: this.itemAddOns});
            this.$vs.loading.close();
            this.loading = false;
          })
          .catch(() => {
            //console.log({e});
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error 🙁",
              text: "Couldn't fetch adds on from the server",
              color: 'danger'
            });
          })
      },
      addAddOnItem() {
        this.addNewData();
        //this.addAddOns = true;
        this.$router.push('/models/adds-on/' + this.item.id);
      },
      addWarrantyItem() {
        this.addNewData();
        this.addWarranties = true;
      },
      addServiceItem() {
        // this.$store.state['auth/model_id'] = this.item.id;
        // this.$store.state['auth/services'] = this.item.services;
        // this.$router.push('/services');
        //
        // console.log({state_model_id: this.$store.state['auth/model_id']});
        // console.log({state_services: this.$store.state['auth/services']});

        //this.addNewData();
        this.$router.push('/models/add-service/' + this.item.id);

        //this.model = this.item;
        //this.addServices = true;
      },
      addNewData() {
        this.sidebarData = this.item;
        console.log(this.item);
      },
      toggleDataSidebar(val = false) {
        this.addAddOns = val;
        this.addServices = val;
        this.addWarranties = val;

      },
      onSuccessOperation() {
        this.getData();
      },
      close() {
        this.$emit('cancel');
      }
    },
    mounted() {
      //console.log(this.item.services[0].details);
      this.loading = true;
      this.getModel();
    },
    updated() {
    }
  }
</script>

<style scoped lang="scss">

  table {
    width: 50%;
    float: left;
    font-size: 1.1rem;
  }

  .flex {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    align-items: center;
  }

  .flex-list {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    align-items: center;
    padding: 10px;
  }

  .main {
    position: relative;
  }

  .dropDown {
    position: absolute;
    top: 0;
    right: 0;
  }

  .list {
    width: 150px;
  }

  td {
    white-space: nowrap;
  }

  .plusIcon {
    position: absolute;
    top: -30px;
    right: 10px;
  }

  .service-section {
    .title {
      color: #054F7C;
      font-size: 15px;
    }
  }

  .price {
    color: #054F7C;
    font-size: 15px;
    font-weight: 600;
  }

  .lang, .title, .text {
    padding: 10px;
  }

  .lang {
    color: #054F7C;
  }

</style>
