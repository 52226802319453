<template>
  <div>
    <!--Warranties Section-->
    <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="12">
      <div class="mt-5 w-full">
        <vs-row
          vs-align="center"
          vs-type="flex"
          vs-justify="space-between"
          vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
            <!--En Title warranty-->
            <vs-input label="English Title*" min="0" v-model="item.warranties[0].en.title" class="mt-5 w-full"
                      name="warranty.en.title"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
            <!--Ar Title warranty-->
            <vs-input label="Arabic Title*" min="0" v-model="item.warranties[0].ar.title" class="mt-5 w-full"
                      name="warranty.ar.title"/>
          </vs-col>


          <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
            <text-area label="English Details" v-model="item.warranties[0].en.text" rows="12"
                       :name="`warranty.en.text`"/>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
            <text-area label="Arabic Details" v-model="item.warranties[0].ar.text" rows="12"
                       :name="`warranty.ar.text`"/>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="2"/>
        </vs-row>
      </div>
    </vs-col>
    <vs-col vs-type="flex" class="pa-4 flex" vs-align="center" vs-w="12">
      <vs-button color="primary" type="filled" @click="confirmSubmitData">Save</vs-button>
      <vs-button color="primary" type="border" @click="$emit('cancel')" style="margin-left: 10px">Cancel</vs-button>
    </vs-col>
  </div>
</template>

<script>

  import TextArea from "../../../../components/form/text-area";
  import {isUrl} from "../../../../utils/functions";

  export default {
    name: 'add-warranties',
    components: {TextArea},
    props: {
      item: {
        required: true,
        default: () => {
        }
      }
    },
    methods: {
      confirmSubmitData() {
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.saveData(),
          color: 'primary',
          title: 'Save Add-Ons',
          text: 'You are going to save this data'
        });
      },
      saveData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            const obj = this.item;
            const formData = new FormData();

            formData.append("name_en", obj.name_en);
            formData.append("name_ar", obj.name_ar);
            formData.append("brand_id", obj.brand.id);
            formData.append("car_type_id", obj.car_type.id);
            formData.append("year", obj.year);
            formData.append("car_type", obj.carType);
            if (!isUrl(obj.image)) {
              formData.append("image", obj.image);
            }
            if (!isUrl(obj.brochure)) {
              formData.append("brochure", obj.brochure);
            }
            formData.append("mobile_active", Number(obj.mobile_active));
            for (let i in obj.services) {
              if (
                obj.services[i].km > 0
                && obj.services[i].price > 0
                && obj.services[i].details.length > 0
              ) {
                formData.append(`services[${i}][km]`, obj.services[i].km);
                formData.append(`services[${i}][price]`, obj.services[i].price);
                for (let j in obj.services[i].details) {
                  if (
                    obj.services[i].details[j].ar.length > 0
                    && obj.services[i].details[j].en.length > 0
                  ) {
                    formData.append(`services[${i}][details][ar][${j}]`, obj.services[i].details[j].ar);
                    formData.append(`services[${i}][details][en][${j}]`, obj.services[i].details[j].en);
                  }

                }
              }
            }

            for (let i in obj.warranties) {
              if (
                obj.warranties[i].ar.title.length > 0
                && obj.warranties[i].ar.text.length > 0
                && obj.warranties[i].en.title.length > 0
                && obj.warranties[i].en.text.length > 0
              ) {
                formData.append(`warranties[${i}][ar][title]`, obj.warranties[i].ar.title);
                formData.append(`warranties[${i}][ar][text]`, obj.warranties[i].ar.text);
                formData.append(`warranties[${i}][en][title]`, obj.warranties[i].en.title);
                formData.append(`warranties[${i}][en][text]`, obj.warranties[i].en.text);
              }
            }

            this.$httpCars.post(`/models/${this.item.id}`, formData)
              .then(() => {
                this.$vs.loading.close();
                this.$emit("onSave");
                this.$vs.notify({
                  title: "Successful operation",
                  text: "Model has been updated successfully",
                  color: 'success'
                });
                this.update();
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't add model to the server",
                  color: 'danger'
                });
                //console.log({error})
              });
          }
          this.$vs.loading.close();
        });
      },
      update() {
        this.$emit('update', this.item.warranties);
      }
    },
    created() {
      if (this.item.services.length !== 0) {
        this.item.services = this.item.services.map(service => {
          let details = service.details;
          let detailsArr = [];
          for (let i in details.en) {
            detailsArr.push({
              en: details.en[i],
              i,
            })
          }

          detailsArr = detailsArr.map(d => {
            for (let i in details.ar) {
              if (d.i === i) {
                d.ar = details.ar[i]
              }
            }

            return d;
          });

          service.details = detailsArr;
          return service
        });
      }
    }
    ,
  }
</script>

<style scoped lang="scss">
  .flex {
    justify-content: flex-end;
    padding-right: 30px;
  }
</style>
